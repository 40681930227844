import React, {Fragment, useEffect, useState} from "react";
import {Box, CircularProgress, FormControlLabel, Icon, IconButton, Switch, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router";
import {
  getClientsAssignments,
  getStatesAssignments,
  getTutorsAssignments,
  operateAssignments
} from "../Core/TetRequests";
import {format, parse} from "date-fns";
import {DatePicker} from "@material-ui/pickers";

const calcTransaction = (typeUser, userId, user, assignmentId) => {
  let transaction = '';
  let idUser = -1;
  if (typeUser === 'T') {
    transaction = 'CT';
    idUser = userId;
  } else if (typeUser === 'A') {
    transaction = 'CA';
  } else if (typeUser === 'P') {
    transaction = 'CP';
  }

  return {
    transaction      : transaction,
    requestId        : assignmentId,
    customerId       : -1,
    idTutor          : idUser,
    nombreTrabajo    : "",
    fechaEntrega     : "",
    fechaEntregaTutor: '',
    costo            : -1,
    porcEjecutor     : -1,
    comision         : -1,
    pagado           : -1,
    idEstado         : -1,
    usuario          : user,
    fechaInicio      : '01/01/2001',
    fechaFin         : '01/01/2999'
  }
}

const AssignmentFormPage = (props) => {
  const history = useHistory();
  const [user] = useState(JSON.parse(sessionStorage.getItem('userInfo')));
  const [idAssignment] = useState(useParams().id);
  const [states, setStates] = useState([]);
  const [clients, setClients] = useState([]);
  const [tutors, setTutors] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [headerFormValues] = useState({
    transaction      : idAssignment ? 'U' : 'I',
    requestId        : Number(idAssignment) || -1,
    customerId       : undefined,   // Manual
    idTutor          : undefined,   // Manual
    nombreTrabajo    : "",          // Manual
    fechaEntrega     : new Date(),  // Manual
    fechaEntregaTutor: new Date(),  // Manual
    costo            : 0,
    porcEjecutor     : 0,           // Manual
    comision         : 0,           // Manual
    pagado           : 0,
    idEstado         : 1,
    usuario          : user.user,
    fechaInicio      : "",
    fechaFin         : ""
  });
  const [errorForm, setErrorForm] = useState({
    errorCustomerId       : false,
    errorIdTutor          : false,
    errorNombreTrabajo    : false,
    errorFechaEntrega     : false,
    errorFechaEntregaTutor: false,
    errorPorcEjecutor     : false,
    errorComision         : false,
    errorCosto            : false,
  });
  const [formValue, setFormValue] = useState({
    customerId       : null,
    idTutor          : null,
    nombreTrabajo    : '',
    fechaEntrega     : new Date(),
    fechaEntregaTutor: new Date(),
    porcEjecutor     : '',
    comision         : '',
    pagado           : false,
    costo            : ''
  });

  useEffect(() => {
    let statesAssignments = getStatesAssignments();
    let clientsAssignments = getClientsAssignments();
    let tutorsAssignments = getTutorsAssignments();

    setLoading(true)
    Promise.all([statesAssignments, clientsAssignments, tutorsAssignments]).then(result => {
      setStates(result[0]);
      setClients(result[1]);
      setTutors(result[2]);

      if (idAssignment) {
        operateAssignments(calcTransaction(user.type, user.idUser, user.user, idAssignment)).then(assignment => {
          if (assignment.length) {
            setFormValue(
              {
                customerId   : result[1].find(client => client["client"] === assignment[0]["cliente"]),
                idTutor      : result[2].find(tutor => tutor["tutor"] === assignment[0]["tutor"]),
                nombreTrabajo: assignment[0]["nombre_trabajo"],
                fechaEntrega : parse(assignment[0]["fecha_entrega"], 'yyyy-MM-dd', new Date()),
                fechaEntregaTutor : parse(assignment[0]["fechaEntregaTutor"], 'yyyy-MM-dd', new Date()),
                porcEjecutor : assignment[0]["ejecutor"],
                comision     : assignment[0]["comision"],
                pagado       : assignment[0]["pagado"] === "SI",
                costo        : assignment[0]["costo"],
              })
            setIsButtonDisabled(false)
          }
        })
      }
    }).finally(() => setLoading(false))
  }, [idAssignment, user.idUser, user.type, user.user]);


  // region Save/Update
  const handleButtonClick = () => {
    const assignmentOperation = {
      ...headerFormValues,
      ...formValue,
      customerId  : formValue.customerId.id,
      idTutor     : formValue.idTutor.id,
      fechaEntrega: format(formValue.fechaEntrega, 'dd/MM/yyyy'),
      fechaEntregaTutor: format(formValue.fechaEntregaTutor, 'dd/MM/yyyy'),
      pagado      : formValue.pagado ? 1 : 0
    }
    setLoading(true);
    operateAssignments(assignmentOperation).then(() => {
      setTimeout(() => {
        history.push("/assignments");
      }, 500)
    }).finally(() => {
      setLoading(false);
    })
  };
  // endregion


  const handleInputChange = (event, nameInput = null, valueObject = null) => {

    let value = '';
    let name = '';
    if (!nameInput && !valueObject && event.target.type !== 'tel') {
      const target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    } else if (event?.target?.type === 'tel') {
      const target = event.target;
      value = target.value.replace(/\D/g, "");
      name = target.name;
    } else {
      value = valueObject;
      name = nameInput
    }
    const nameError = 'error' + name.slice(0, 1).toUpperCase() + name.slice(1)

    setErrorForm({
      ...errorForm,
      [nameError]: !Boolean(value)
    })

    setFormValue({
      ...formValue,
      [name]: value,
    });
  }

  const handleFilterFormChange = (date, fieldName) => {
    setFormValue({
        ...formValue,
        [fieldName]: date
      }
    )
  }

  useEffect(() => {
    const allValuesInputs = Object.values(formValue)
    setIsButtonDisabled((allValuesInputs.some(value => value === null || value === '')))
  }, [formValue]);

  return (
    <Fragment>
      <Box className={'main-container'} boxShadow={2} position={'relative'}>
        <Box fontWeight={500} fontSize={'32px'}>
          <p>Información Solicitud</p>
        </Box>
        <Box position={'absolute'} left={'10px'}>
          <Link to={`/assignments`}>
            <IconButton>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Link>
        </Box>
      </Box>
      <Box display={'flex'} gridGap={'12px'} justifyContent={"space-between"} padding={2}>
        <Typography variant={"h4"}>{idAssignment ? `Edición Id: ${idAssignment}` : 'Creación'}</Typography>
        {user.type === 'P' &&
        <FormControlLabel
          style={{transform: 'scale(1.2)', margin: '0'}}
          label="Pagado"
          labelPlacement="start"
          control={
            <Switch
              checked={formValue?.pagado || false}
              onChange={e => handleInputChange(e)}
              name="pagado"
              color="secondary"
            />
          }
        />}
      </Box>
      <Box display={'flex'} flexDirection={{xs: "column", md: "row"}}>
        <Box flex={'1 1 50%'} display={'flex'}
             flexDirection={'column'}
             padding={'16px'}
             gridGap={'12px'}
        >

          <Autocomplete
            options={clients}
            name={'customerId'}
            getOptionLabel={(option) => option.client}
            value={formValue.customerId}
            onChange={(event, newValue) => {
              handleInputChange(undefined, 'customerId', newValue)
            }}
            getOptionSelected={(option, value) => {
              return option.id === value.id

            }
            }
            renderInput={(params) =>
              <TextField {...params}
                         error={errorForm.errorIdCiudad}
                         label="Cliente"
                         variant="outlined"
                         color={'secondary'}
                         helperText="Ingrese Cliente"/>}
          />

          <Autocomplete
            options={tutors}
            name={'idTutor'}
            getOptionLabel={(option) => option.tutor}
            value={formValue.idTutor}
            onChange={(event, newValue) => {
              handleInputChange(undefined, 'idTutor', newValue)
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) =>
              <TextField {...params}
                         error={errorForm.errorIdCiudad}
                         label="Tutor"
                         variant="outlined"
                         color={'secondary'}
                         helperText="Ingrese Tutor"/>}
          />

          <TextField
            error={errorForm.errorNombreTrabajo}
            name={'nombreTrabajo'}
            value={formValue.nombreTrabajo}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            label="Nombre Trabajo"
            helperText="Ingrese nombre del Trabajo"
            variant="outlined"
          />

        </Box>
        <Box>
          <Divider orientation="vertical"/>
        </Box>
        <Box flex={'1 1 50%'} display={'flex'}
             flexDirection={'column'}
             padding={'16px'}
             gridGap={'12px'}
        >
          <DatePicker
            style={{flex: 'auto'}}
            color='secondary'
            variant="inline"
            orientation="portrait"
            inputVariant="outlined"
            label="Fecha entrega"
            format="dd/MM/yyyy"
            value={formValue.fechaEntrega}
            onChange={date => handleFilterFormChange(date, 'fechaEntrega')}
          />

          <DatePicker
            style={{flex: 'auto'}}
            color='secondary'
            variant="inline"
            orientation="portrait"
            inputVariant="outlined"
            label="Fecha entrega tutor"
            format="dd/MM/yyyy"
            value={formValue.fechaEntregaTutor}
            onChange={date => handleFilterFormChange(date, 'fechaEntregaTutor')}
          />

          <TextField
            error={errorForm.errorPorcEjecutor}
            name={'porcEjecutor'}
            value={formValue.porcEjecutor}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            label="Valor tutor"
            type={'tel'}
            helperText="Ingrese valor tutor"
            variant="outlined"
          />

          <TextField
            error={errorForm.errorComision}
            name={'comision'}
            value={formValue.comision}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            type={'tel'}
            label="Comisión"
            helperText="Ingrese comisión"
            variant="outlined"
          />

          <TextField
            error={errorForm.errorCosto}
            name={'costo'}
            value={formValue.costo}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            type={'tel'}
            label="Costo"
            helperText="Ingrese costo"
            variant="outlined"
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <div className={'wrapper'}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={loading || isButtonDisabled}
            onClick={handleButtonClick}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </Button>
          {loading && <CircularProgress size={24} color={'secondary'} className={'buttonProgress'}/>}
        </div>
      </Box>
    </Fragment>
  );
};

export default AssignmentFormPage;

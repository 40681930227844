import React from "react";
import Box from "@material-ui/core/Box";

import "./FilterHeader.scss"
import {Button, Icon, Typography} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import PropTypes from "prop-types";
import {FILTER_NAME_DATE} from "../../../../constants";

const FilterNameDates = (props) => {
  return (
    <Box className={'main-container'} boxShadow={2}>
      <Box fontWeight={500} fontSize={'32px'}>
        <p>{props.name}</p>
      </Box>
      <div className={'filter-container'}>
        <form onSubmit={e => props.handleSubmitFilter(e)} noValidate autoComplete="off">
          <Box>
            <Typography variant={'h5'}>{FILTER_NAME_DATE.FILTER_TITLE}</Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={{xs: 'column', md: 'row'}}
            alignItems={{xs: 'auto', sm: 'flex-end'}}
            justifyContent={{xs: 'center', sm: 'flex-end'}}
            height={1}
            gridGap={8} marginTop={'8px'}>
            <Box
              display={'flex'}
              flexDirection={{xs: 'column', sm: 'row'}}
              gridGap={8}
            >
              <DatePicker
                size='small'
                color='secondary'
                variant="inline"
                orientation="portrait"
                inputVariant="outlined"
                label={FILTER_NAME_DATE.START_DATE_LABEL}
                format="dd/MM/yyyy"
                value={props.filterForm.startDate}
                onChange={date => props.handleFilterFormChange(date, 'startDate')}
              />
              <DatePicker
                size='small'
                color='secondary'
                variant="inline"
                orientation="portrait"
                inputVariant="outlined"
                label={FILTER_NAME_DATE.END_DATE_LABEL}
                format="dd/MM/yyyy"
                value={props.filterForm.endDate}
                onChange={date => props.handleFilterFormChange(date, 'endDate')}
              />
              <Button
                type='submit'
                variant="contained"
                color="secondary"
                startIcon={<Icon>{FILTER_NAME_DATE.FILTER_ICON}</Icon>}
              >
                {FILTER_NAME_DATE.FILTER_TEXT}
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    </Box>
  );
};

FilterNameDates.propTypes = {
  name                  : PropTypes.string,
  handleFilterFormChange: PropTypes.func,
  handleSubmitFilter    : PropTypes.func,
  filterForm            : PropTypes.object
};

export default FilterNameDates;
import {Dialog, DialogTitle, List, ListItem, ListItemText} from "@material-ui/core";
import PropTypes from "prop-types";

function SimpleDialog(props) {
  const {onClose, selectedValue, open} = props;

  const handleClose = () => {
    onClose(selectedValue, props.idSolicitudSelected);
  };

  const handleListItemClick = (value) => {
    onClose(value, props.idSolicitudSelected);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Estados</DialogTitle>
      <List style={{minWidth: '300px'}}>
        {props.states.map((state) => (
          <ListItem button onClick={() => handleListItemClick(state)} key={state.id}>
            <ListItemText primary={state["estado"]}/>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose      : PropTypes.func.isRequired,
  open         : PropTypes.bool.isRequired,
  idSolicitudSelected: PropTypes.object,
  states       : PropTypes.array.isRequired
};

export default SimpleDialog;
import React, {useState} from "react";
import "./HomePage.scss"
import logoTextImg from "../../assets/images/logo-text.png"
import {NavLink} from "react-router-dom";
import * as Icons from '@material-ui/icons/'
import {menu} from "../shared/Constants/Constants";

const HomePage = () => {
  const [user] = useState(
    JSON.parse(sessionStorage.getItem('userInfo')) ||
    {user: 'Invalid', type: 'Invalid'});

  return (
    <div className={'container-home'}>
      <img src={logoTextImg} alt="Logo text" className={'img-logo-text'}/>
      <div className={'direct-links'}>
        {
          menu.filter(option => option.role.includes(user?.type || '')).slice(0, 3).map(item => (
            <NavLink key={item.id} to={item.route}>
              <div className="button">
                {React.createElement(Icons[item.icon])}
                <span>{item.name}</span>
              </div>
            </NavLink>)
          )}
      </div>
    </div>
  );
};

export default HomePage;


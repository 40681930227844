import React, {useEffect, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {Button, CircularProgress, Divider, Icon, IconButton, TablePagination} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import "./BasicTableFilter.scss"
import PropTypes from "prop-types";
import FilterButton from "./Components/FilterButton";
import {BASIC_TABLE_FILTER} from "../../../../constants";
import {Link} from "react-router-dom";
import {exportAsExcelFile} from "../../../Core/excelGenerate";


const BasicTableFilter = (props) => {
  //region State
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [filterWord, setFilterWord] = useState('');
  const [dataRow, setDataRow] = useState(props.rows?.slice());
  const [dataHeader] = useState(props.columns);
  //endregion

  useEffect(() => {
    setDataRow(props.rows?.slice())
  }, [props.rows]);

  //region Filter - Paginator Table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangeFilterWord = (e) => {
    setFilterWord(e.target.value);
    const word = e.target.value;
    if (word) {
      setDataRow(props.rows.filter(el => Object.values(el).join(' ').toLowerCase().includes(String(word).toLowerCase())))
    } else {
      setDataRow(props.rows.slice())
    }
    setPage(0);
  };
  //endregion
  // region Excel
  const handleExcelGenerateDownload = () => {
    let dataBottom = [];

    dataRow.forEach(data => {
      const objTable = {};
      dataHeader.forEach(col => {
        objTable[col.idColumn] = data[col.idColumn]
      })
      dataBottom.push(objTable)
    });

    let dataTop = [dataHeader.slice(0, -1).map(col => col.label)];
    let width = dataHeader.map(col => {
      return {width: (col.widthReportColumn || 10)}
    })

    exportAsExcelFile(dataBottom, `Reporte_${props.name}`, dataTop, width);
  }
  // endregion

  return (
    <Paper style={{marginTop: '16px'}}>
      <Box display={'flex'} paddingX={'4px'} style={{background: '#00000005'}}>
        <Box flex='1 1 auto' display='flex' alignItems='center'>
          <Box flex={'0 0 40px'} display='flex' justifyContent='center' alignItems='center'>
            <Icon>{BASIC_TABLE_FILTER.ICON_SEARCH}</Icon>
          </Box>
          <Box flex={'1 1 100%'}>
            <input
              placeholder={BASIC_TABLE_FILTER.PLACEHOLDER_SEARCH_BAR}
              className='input-search-table'
              value={filterWord}
              onChange={e => handleChangeFilterWord(e)}
              type="text"/>
          </Box>
        </Box>
        <Box flex={'0 0 200px'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          {props.routeForm &&
          <Link to={props.routeForm}>
            <Button
              type='submit'
              variant="contained"
              color="secondary"
              startIcon={<Icon>{BASIC_TABLE_FILTER.ADD_BUTTON_ICON}</Icon>}
            >
              {BASIC_TABLE_FILTER.ADD_BUTTON_TEXT}
            </Button>
          </Link>
          }
          <FilterButton columns={dataHeader} handleEnableDisableColumn={props.handleEnableDisableColumn}/>
          <IconButton size="medium" onClick={() => handleExcelGenerateDownload()}>
            <Icon>file_download</Icon>
          </IconButton>
        </Box>
      </Box>
      <Divider/>
      {<TableContainer className={'basic-table-container'}>
        {props.showLoading && <Box display={'flex'} height={1} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress color={'secondary'}/>
        </Box>}
        {!props.showLoading &&
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {dataHeader.map((column) => (
                column.show &&
                <TableCell
                  key={column.idColumn}
                  align={column.align}
                  style={{width: column.width}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              {props.errorMessage &&
              <TableCell align='center' colSpan={dataHeader.length}>
                🚧 {props.errorMessage} 🚧
              </TableCell>}
              {dataRow.length === 0 && !props.errorMessage &&
              <TableCell align='center' colSpan={dataHeader.length}>
                🔎 {filterWord ? BASIC_TABLE_FILTER.NOT_FOUND_RESULTS_X_FILTER + '"' + filterWord + '"' : BASIC_TABLE_FILTER.NOT_FOUND_RESULTS} 🔍
              </TableCell>}
            </TableRow>
            {dataRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row[props.idColumnName]}>
                  {dataHeader.map((column) => {
                    const value = row[column.idColumn];
                    const idColumnDom = column.idColumn;
                    return (
                      column.show &&
                      <TableCell
                        key={idColumnDom}
                        align={column.align}
                        style={{padding: '12px'}}
                      >
                        {
                          (column.customRender && column.customRender(value, row)) ||
                          (value || '-')
                        }
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        }
      </TableContainer>}
      {props.routeForm &&
      <TablePagination
        rowsPerPageOptions={BASIC_TABLE_FILTER.TABLE_PAGINATOR}
        component="div"
        count={dataRow.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      }
    </Paper>
  );
};

BasicTableFilter.propTypes = {
  rows        : PropTypes.array,
  columns     : PropTypes.array,
  showLoading : PropTypes.bool,
  errorMessage: PropTypes.string,
  addHandler  : PropTypes.func,
  editHandler : PropTypes.func,
  routeForm   : PropTypes.string
};

export default BasicTableFilter;
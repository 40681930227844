import React, {Fragment} from "react";
import {Avatar, Box, ButtonBase, Hidden, Popover, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import "./AvatarUserButton.scss"

const AvatarUserButton = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <ButtonBase className={'avatar-user'} onClick={handleClick}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent="center"
          alignItems="center"
          gridGap={8}
        >
          <Avatar className="avatar blue-back">
            <Box fontSize="18px">
              {props.userName.split(' ')[0].charAt(0).toUpperCase() + (props.userName.split(' ')[1]?.charAt(0).toUpperCase() || '')}
            </Box>
          </Avatar>
          <Hidden only={['xs']}>
            <Box display="flex"
                 flexDirection="column"
                 alignItems='flex-start'>
              <Box fontWeight="fontWeightBold" lineHeight={'13px'}>{props.userName.charAt(0).toUpperCase() + props.userName.toLowerCase().slice(1)}</Box>
              <Box lineHeight={'13px'}>{props.userRole === 'A' ? 'Admin': props.userRole === 'T'? 'Tutor' : 'Propietario'}</Box>
            </Box>
          </Hidden>
        </Box>
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          className={'pos-rel'}
          p={2}
          display="flex"
          gridGap={4}
          flexDirection="column" alignItems="center" minWidth={'150px'}>
          <Avatar className="avatar blue-back avatar-expand">
            <Box fontSize="28px">
              {props.userName.split(' ')[0].charAt(0).toUpperCase() + (props.userName.split(' ')[1]?.charAt(0).toUpperCase() || '')}
            </Box>
          </Avatar>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h3>{props.userName.charAt(0).toUpperCase() + props.userName.toLowerCase().slice(1)}</h3>
            <h4 className={'col-gray'}>{props.userRole === 'A' ? 'Admin': props.userRole === 'T'? 'Tutor' : 'Cliente'}</h4>
          </Box>
          <Box p={1}/>
          <div className={'logout-pos'}>
            <Tooltip title="Cerrar Sesión" arrow placement="left">
              <IconButton aria-label="delete" onClick={e => props.handleLogOut(e)}>
                <PowerSettingsNewIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </Popover>
    </Fragment>

  );
};

export default AvatarUserButton;

export const MAIN_PAGE_CONSTANTS = {
  //Social Media
  URI_MAIL  : 'mailto:tesosentesis@gmail.com',
  URI_FB_TET: 'https://www.facebook.com/tesosentesis',
  URI_WP_TET: 'https://wa.me/573114758457',
  URI_INSTA_TET: 'https://www.instagram.com/tesosentesis/?hl=es-la',
  //Text
  TXT_MAIN           : 'Somos una empresa que da soluciones académicas a TODO nivel.',
  TXT_CONTACT        : 'Contáctanos a través de ',
  TXT_CONTACT_SECTION: 'Contáctanos',
  TXT_MAIL           : 'tesosentesis@gmail.com',
  TXT_PW_ERROR       : 'Usuario/Contraseña Invalido.',
  TXT_FB             : 'Facebook',
  TXT_WA             : 'Whatsapp',
  TXT_EMAIL          : 'Correo',
  TXT_INSTA          : 'Instagram',
}

export const FILTER_NAME_DATE = {
  FILTER_TITLE    : 'Filtros',
  START_DATE_LABEL: 'Fecha Inicio',
  END_DATE_LABEL  : 'Fecha Fin',
  FILTER_ICON     : 'filter_alt',
  FILTER_TEXT     : 'Filtrar'
}

export const BASIC_TABLE_FILTER = {
  ICON_SEARCH               : 'search',
  PLACEHOLDER_SEARCH_BAR    : 'Buscar...',
  ADD_BUTTON_ICON           : 'add',
  ADD_BUTTON_TEXT           : 'Añadir',
  NOT_FOUND_RESULTS         : 'No existen resultados',
  NOT_FOUND_RESULTS_X_FILTER: 'No existen resultados por: ',
  TABLE_PAGINATOR           : [5, 15, 25, 100],
  MENU_EDIT_OPTION          : 'Editar'
}

export const FILTER_BUTTON = {
  FILTER_TITLE: 'Columnas'
}
import React, {Fragment, useEffect, useState} from "react";
import {getYear} from "date-fns";
import FilterYear from "../shared/components/FilterHeader/FilterYear";
import {getReportData} from "../Core/TetRequests";
import {Box, Paper, Tab, Tabs} from "@material-ui/core";
import PropTypes from "prop-types";
import BasicTableFilter from "../shared/components/BasicTableFilter/BasicTableFilter";
import SwipeableViews from 'react-swipeable-views';


function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        props.children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index   : PropTypes.any.isRequired,
  value   : PropTypes.any.isRequired,
};

const ReportPage = () => {
  const [optionsYears] = useState([
    String(getYear(new Date())),
    String(getYear(new Date()) - 1),
    String(getYear(new Date()) - 2),
    String(getYear(new Date()) - 3),
    String(getYear(new Date()) - 4)
  ]);
  const [value, setValue] = useState(String(optionsYears[0]));
  const [currentValue, setCurrentValue] = useState(String(optionsYears[0]));


  const [dataHeader, setDataHeader] = useState([
    {
      widthReportColumn: 30,
      idColumn         : 'mes',
      label            : 'Mes',
      show             : true,
    },
    {
      widthReportColumn: 10,
      idColumn         : 'costo',
      label            : 'Costo',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },
    {
      widthReportColumn: 10,
      idColumn         : 'ejecutor',
      label            : 'Ejecutor',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },
    {
      widthReportColumn: 10,
      idColumn         : 'comision',
      label            : 'Comisión',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },
    {
      widthReportColumn: 10,
      idColumn         : 'utilidad',
      label            : 'Utilidad',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },
    {
      widthReportColumn: 10,
      idColumn         : 'ahorro',
      label            : 'Ahorro',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },
    {widthReportColumn: 10, idColumn: 'inversion', label: 'Inversion', show: true},
    {widthReportColumn: 10, idColumn: 'diezmo', label: 'Diezmo', show: true},
  ]);
  const [dataTablePaid, setDataTablePaid] = useState([]);
  const [showLoadingPaid, setShowLoadingPaid] = useState(true);
  const [errorMessagePaid, setErrorMessagePaid] = useState(null);
  const [dataTablePending, setDataTablePending] = useState([]);
  const [showLoadingPending, setShowLoadingPending] = useState(true);
  const [errorMessagePending, setErrorMessagePending] = useState(null);


  const getData = (isPaid) => {

    isPaid ? setShowLoadingPaid(true) : setShowLoadingPending(true)
    getReportData(currentValue, isPaid).then(data => {
      let total = [0, 0, 0, 0, 0, 0, 0]
      data.forEach(month => {
        total[0] += month.costo;
        total[1] += month.ejecutor;
        total[2] += month.comision;
        total[3] += month.utilidad;
        total[4] += month.ahorro;
        total[5] += month.inversion;
        total[6] += month.diezmo;
      })
      data.push({
        mes      : "TOTAL",
        costo    : total[0],
        ejecutor : total[1],
        comision : total[2],
        utilidad : total[3],
        ahorro   : total[4],
        inversion: total[5],
        diezmo   : total[6]
      })
      isPaid ? setDataTablePaid(data) : setDataTablePending(data)
    }).catch(() => {
      const error = 'Ops.. Sucedió un error';
      isPaid ? setErrorMessagePaid(error) : setErrorMessagePending(error)
    }).finally(() => {
      isPaid ? setShowLoadingPaid(false) : setShowLoadingPending(false)
    })


  }

  useEffect(() => {
    getData(1)
    getData(0)
  }, [currentValue]);


  const handlerOnChange = (e) => setValue(e.target.value)
  const handleSubmitFilter = e => {
    e.preventDefault()
    setCurrentValue(value)
    getData(value)
  };


  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeOnMainContainer = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEnableDisableColumn = (e, idColumn) => {
    const originalDataHeader = dataHeader.slice();
    originalDataHeader[originalDataHeader.findIndex(column => column.idColumn === idColumn)].show = e.target.checked
    setDataHeader(originalDataHeader)
  };

  const handleChangeIndex = (index) => {
    setTabIndex(Number(index));
  };

  return (
    <Fragment>
      <FilterYear
        name="Reporte"
        years={optionsYears}
        handlerOnChange={(e) => handlerOnChange(e)}
        handleSubmitFilter={(e) => handleSubmitFilter(e)}
        value={value}
      />

      <Box marginTop={'8px'}>
        <Paper>
          <Paper>
            <Tabs
              value={tabIndex}
              onChange={handleChangeOnMainContainer}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
            >
              <Tab label={`Pagadas ${currentValue}`}/>
              <Tab label={`Pendientes ${currentValue}`}/>
            </Tabs>
          </Paper>

          <SwipeableViews
            axis={'x'}
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >

            <TabPanel value={tabIndex} index={0}>
              <Box margin={'8px'}>
                <Paper>
                  <BasicTableFilter
                    name={`Pagadas ${currentValue}`}
                    columns={dataHeader}
                    rows={dataTablePaid}
                    errorMessage={errorMessagePaid}
                    idColumnName='mes'
                    handleEnableDisableColumn={handleEnableDisableColumn}
                    showLoading={showLoadingPaid}
                  />
                </Paper>
              </Box>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Box margin={'8px'}>
                <Paper>
                  <BasicTableFilter
                    name={`Pendientes ${currentValue}`}
                    columns={dataHeader}
                    rows={dataTablePending}
                    errorMessage={errorMessagePending}
                    idColumnName='mes'
                    handleEnableDisableColumn={handleEnableDisableColumn}
                    showLoading={showLoadingPending}
                  />
                </Paper>
              </Box>
            </TabPanel>
          </SwipeableViews>
        </Paper>
      </Box>


    </Fragment>
  );
};

export default ReportPage;

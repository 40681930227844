import React, {Fragment, useEffect, useState} from "react";
import FilterAssignment from "../shared/components/FilterHeader/FilterAssignment";
import {format, sub} from "date-fns";
import {getStatesAssignments, getTutor, operateAssignments, setStateAssignments} from "../Core/TetRequests";
import FilterNameDates from "../shared/components/FilterHeader/FilterNameDates";
import BasicTableFilter from "../shared/components/BasicTableFilter/BasicTableFilter";
import {Box, Icon, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import SimpleDialog from "./SimpleDialog"
import {Alert, AlertTitle} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";

const calcTransaction = (typeUser, userId, user, startDate, endDate) => {
  let transaction = '';
  let idUser = -1;
  if (typeUser === 'T') {
    transaction = 'CT';
    idUser = userId;
  } else if (typeUser === 'A') {
    transaction = 'CA';
  } else if (typeUser === 'P') {
    transaction = 'CP';
  }

  return {
    transaction      : transaction,
    requestId        : -1,
    customerId       : -1,
    idTutor          : idUser,
    nombreTrabajo    : '',
    fechaEntrega     : '',
    costo            : -1,
    porcEjecutor     : -1,
    comision         : -1,
    pagado           : -1,
    idEstado         : -1,
    usuario          : user,
    fechaInicio      : format(startDate, 'dd/MM/yyyy'),
    fechaFin         : format(endDate, 'dd/MM/yyyy'),
    fechaEntregaTutor: '',
  }
}

const AssignmentPage = (props) => {

  const calcHeaders = (typeUser) => {
    if (typeUser === 'T') {
      return [
        {idColumn: 'idSolicitud', label: 'ID', show: true},
        {idColumn: 'fechaCreacion', label: 'Dia', show: true},
        {idColumn: 'fecha_entrega', label: 'Entrega', show: true},
        {idColumn: 'cliente', label: 'Cliente', show: true},
        {idColumn: 'nombre_trabajo', label: 'Titulo', show: true},
        {
          idColumn    : 'ejecutor',
          label       : 'Valor tutor',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {
          idColumn    : 'estado', label: 'Estado', show: true,
          customRender: (value, row) => <Box display={'flex'} alignItems={'center'}>
            {value}
            <IconButton size={"small"} onClick={e => handleClickOpen(row)}>
              <Icon>edit</Icon>
            </IconButton>
          </Box>
        },
        {idColumn: 'usr_modificacion', label: 'Usr. Modificación', show: false},
        {idColumn: 'fecha_modificacion', label: 'Fecha Modificación', show: false}
      ]
    } else if (typeUser === 'A') {
      return [
        {idColumn: 'idSolicitud', label: 'ID', show: true},
        {idColumn: 'fechaCreacion', label: 'Dia', show: true},
        {idColumn: 'fecha_entrega', label: 'Entrega', show: true},
        {idColumn: 'fechaEntregaTutor', label: 'Entrega Tutor', show: true},
        {idColumn: 'cliente', label: 'Cliente', show: true},
        {idColumn: 'nombre_trabajo', label: 'Titulo', show: true},
        {
          idColumn    : 'costo',
          label       : 'Costo',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {idColumn: 'tutor', label: 'Tutor', show: true},
        {idColumn: 'comision', label: 'Comisión', show: true},
        {
          idColumn    : 'ejecutor',
          label       : 'Valor tutor',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {
          idColumn    : 'estado', label: 'Estado', show: true,
          customRender: (value, row) => <Box display={'flex'} alignItems={'center'}>
            {value}
            <IconButton size={"small"} onClick={e => handleClickOpen(row)}>
              <Icon>edit</Icon>
            </IconButton>
          </Box>
        },
        {idColumn: 'usr_modificacion', label: 'Usr. Modificación', show: false},
        {idColumn: 'fecha_modificacion', label: 'Fecha Modificación', show: false},
        {
          idColumn: 'actions', label: 'Acciones', width: '100px', show: true, customRender: (value, row) => {
            return <Link to={`/assignments/form/${row['idSolicitud']}`}>
              <IconButton size={"small"}>
                <Icon>edit</Icon>
              </IconButton>
            </Link>
          }
        }
      ]
    } else if (typeUser === 'P') {
      return [
        {idColumn: 'idSolicitud', label: 'ID', show: true},
        {idColumn: 'fechaCreacion', label: 'Dia', show: true},
        {idColumn: 'fecha_entrega', label: 'Entrega', show: true},
        {idColumn: 'fechaEntregaTutor', label: 'Entrega Tutor', show: true},
        {idColumn: 'cliente', label: 'Cliente', show: true},
        {idColumn: 'nombre_trabajo', label: 'Titulo', show: true},
        {
          idColumn    : 'costo',
          label       : 'Costo',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {idColumn: 'tutor', label: 'Tutor', show: true},
        {idColumn: 'comision', label: 'Comisión', show: true},
        {
          idColumn    : 'ejecutor',
          label       : 'Valor tutor',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {
          idColumn    : 'utilidad',
          label       : '% Utilidad',
          show        : true,
          customRender: (value, row) => `$ ${value.toLocaleString('es-CO')}`
        },
        {idColumn: 'ahorro', label: 'Ahorro', show: true},
        {idColumn: 'inversion', label: 'Inversión', show: true},
        {idColumn: 'diezmo', label: 'Diezmo', show: true},
        {idColumn: 'pagado', label: 'Pagado', show: true},
        {
          idColumn    : 'estado', label: 'Estado', show: true,
          customRender: (value, row) => <Box display={'flex'} alignItems={'center'}>
            {value}
            <IconButton size={"small"} onClick={e => handleClickOpen(row)}>
              <Icon>edit</Icon>
            </IconButton>
          </Box>
        },
        {idColumn: 'usr_modificacion', label: 'Usr. Modificación', show: false},
        {idColumn: 'fecha_modificacion', label: 'Fecha Modificación', show: false},
        {
          idColumn: 'actions', label: 'Acciones', width: '100px', show: true, customRender: (value, row) => {
            return <Link to={`/assignments/form/${row['idSolicitud']}`}>
              <IconButton size={"small"}>
                <Icon>edit</Icon>
              </IconButton>
            </Link>
          }
        }
      ]
    }
  }


  const [filterForm, setFilterForm] = useState({
    startDate: sub(new Date(), {years: 1}),
    endDate  : new Date()
  });
  const [currentFilter, setCurrentFilter] = useState({
    startDate: sub(new Date(), {years: 1}),
    endDate  : new Date()
  });

  const [currentUser] = useState(JSON.parse(sessionStorage.getItem('userInfo')));
  const [idTutorCurrently] = useState(JSON.parse(sessionStorage.getItem('userInfo')).idUser);
  const [currentTutor, setCurrentTutor] = useState({});
  const [states, setStates] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataHeader, setDataHeader] = useState(calcHeaders(currentUser.type));
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  let handleFilterFormChange = (date, fieldName) => {
    setFilterForm({
        ...filterForm,
        [fieldName]: date
      }
    )
  }
  let handleSubmitFilter = (event) => {
    event.preventDefault();
    setCurrentFilter(filterForm)
  }


  useEffect(() => {
    if (idTutorCurrently !== -1) {
      getTutor(idTutorCurrently).then(tutor => {
        setCurrentTutor(tutor[0])
      })
    }

    getStatesAssignments().then(states => {
      setStates(states)
    })


  }, [idTutorCurrently]);


  useEffect(() => {
    setShowLoading(true)
    operateAssignments(
      calcTransaction(
        currentUser.type,
        idTutorCurrently,
        currentUser.user,
        currentFilter.startDate,
        currentFilter.endDate)).then(assigments => {
      setDataTable(assigments)
    }).catch(() => {
      setErrorMessage('Ops.. Sucedió un error')
    }).finally(() => setShowLoading(false))
  }, [currentFilter, currentUser, idTutorCurrently]);


  const handleEnableDisableColumn = (e, idColumn) => {
    const originalDataHeader = dataHeader.slice();
    originalDataHeader[originalDataHeader.findIndex(column => column.idColumn === idColumn)].show = e.target.checked
    setDataHeader(originalDataHeader)
  };


  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState({});

  const handleClickOpen = (idSolicitud) => {
    setSelectedValue(idSolicitud);
    setOpen(true);
  };

  const handleClose = (value, Solicitud) => {
    if (value && Solicitud) {

      const setState = {
        idAssigment  : Solicitud["idSolicitud"],
        idStatus     : value.id,
        userName     : currentUser.user,
        destino1     : Solicitud["correoTutor"],
        destino2     : Solicitud["correoCliente"],
        numero       : Solicitud["idSolicitud"],
        nombreTrabajo: Solicitud["nombre_trabajo"],
        fecha        : Solicitud["fecha_entrega"],
        tutor        : Solicitud.tutor,
        cliente      : Solicitud.cliente,
        estado       : value.estado
      }
      setStateAssignments(setState).then(data => {
        if (data[0].id === 1) {
          dataTable.find(el => el["idSolicitud"] === Solicitud["idSolicitud"]).estado = value.estado
          setOpenAlertSuccess(true)
        } else {
          setOpenAlertError(true)
        }
      }).catch(() => {
        setOpenAlertError(true)
      })

    }
    setOpen(false);
    setSelectedValue(undefined);


  };


  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertSuccess(false)
    setOpenAlertError(false)
  };

  return (
    <Fragment>
      <Snackbar
        open={openAlertSuccess}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
      >
        <Alert
          elevation={6}
          onClose={handleCloseAlert}
          variant="filled"
          severity="success"
        >
          <AlertTitle>Estado actualizado.</AlertTitle>
        </Alert>
      </Snackbar>

      <Snackbar
        open={openAlertError}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
      >
        <Alert
          elevation={6}
          onClose={handleCloseAlert}
          variant="filled"
          severity="error"
        >
          <AlertTitle>Error al actualizar.</AlertTitle>
        </Alert>
      </Snackbar>

      <SimpleDialog idSolicitudSelected={selectedValue} open={open} onClose={handleClose} states={states}/>
      {
        (
          idTutorCurrently !== -1 &&
          <FilterAssignment name="Solicitudes"
                            handleFilterFormChange={handleFilterFormChange}
                            handleSubmitFilter={handleSubmitFilter}
                            filterForm={filterForm}
                            currentlyTutor={currentTutor}
          />
        ) ||
        <FilterNameDates name="Solicitudes"
                         handleFilterFormChange={handleFilterFormChange}
                         handleSubmitFilter={handleSubmitFilter}
                         filterForm={filterForm}/>
      }
      <BasicTableFilter
        name='Assignments'
        columns={dataHeader}
        rows={dataTable}
        errorMessage={errorMessage}
        idColumnName='idSolicitud'
        handleEnableDisableColumn={handleEnableDisableColumn}
        showLoading={showLoading}
        routeForm={idTutorCurrently !== -1 ? '' : 'assignments/form'}
      />

    </Fragment>
  );
};

export default AssignmentPage;

const prod = {
  VERSION : 'PROD - v4.0.0',
  BASE_URL: 'https://tet-backend.herokuapp.com/tet-api/'
};

const dev = {
  VERSION : 'DEV - v4.0.0',
  BASE_URL: 'http://localhost:8081/tet-api/'
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

const CurrentEnvironment = {
  ...config
};
export default CurrentEnvironment;

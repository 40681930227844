import React, {Fragment} from "react";
import {Icon, IconButton, Popover, Switch} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {FILTER_BUTTON} from "../../../../../constants";

const FilterButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fragment>
      <IconButton size="medium" onClick={handleClick}>
        <Icon>filter_alt</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'center',
        }}
      >

        <Box display={'flex'} flexDirection={'column'} padding={'16px'}>
          <Typography variant={"h6"}>{FILTER_BUTTON.FILTER_TITLE}</Typography>
          {props.columns.map(column =>
            <div key={column.idColumn}>
              <Box display={'flex'} flexDirection={'row'} gridGap={'8px'}>
                <Box flex={'0 0 30px'}>
                  <Switch
                    size={"small"}
                    checked={column.show}
                    onChange={e => props.handleEnableDisableColumn(e, column.idColumn)}
                  />
                </Box>
                <Box flex={'1 1 auto'}>
                  <Typography>{column.label}</Typography>
                </Box>
              </Box>
            </div>
          )}
        </Box>
      </Popover>
    </Fragment>
  );
};

export default FilterButton;
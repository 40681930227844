import React from "react";
import {Avatar, Box, Button, Icon, Typography} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {FILTER_NAME_DATE} from "../../../../constants";
import PropTypes from "prop-types";
import "./FilterHeader.scss"

const FilterAssignment = (props) => {
  return (
    <Box className={'main-container'} boxShadow={2}>
      <Box fontWeight={500} fontSize={'32px'}>
        <p>{props.name}</p>
      </Box>
      <Box display={'flex'} width={'100%'} gridGap={'8px'} flexDirection={{xs: 'column', md: 'row'}}>
        <div className={'filter-container'} style={{flex: "1 1 70%", width: '100%'}}>
          <Box display={'flex'} height={'100%'} gridGap={'8px'}>
            <Box style={{flex: "0 0 120px"}} height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'}
                 alignItems={'center'}>
              <Avatar className="avatar blue-back" variant={"rounded"} style={{height: '100px', width: '100px'}}>
                <Box fontSize="35px">
                  {(props.currentlyTutor?.nombreCompletoValue || '').split(' ')[0].charAt(0).toUpperCase() + ((props.currentlyTutor?.nombreCompletoValue || '').split(' ')[1]?.charAt(0).toUpperCase() || '')}
                </Box>
              </Avatar>
            </Box>
            <Box style={{flex: "1 1 75%"}} display={'flex'}>
              <Box flex={'0 0 70px'} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                <Typography style={{fontWeight: "bold"}}>Nombre:</Typography>
                <Typography style={{fontWeight: "bold"}}>Teléfono:</Typography>
                <Typography style={{fontWeight: "bold"}}>Correo:</Typography>

              </Box>
              <Box flex={'1 1 auto'} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                <Typography>{props.currentlyTutor?.nombreCompletoValue || ''}</Typography>
                <Typography>
                  <a href={`tel:${props.currentlyTutor?.telefonoValue || ''}`}>
                    {props.currentlyTutor?.telefonoValue || ''}
                  </a>
                </Typography>
                <Typography><a href={`mailto:${props.currentlyTutor?.correoValue || ''}`}>
                  {props.currentlyTutor?.correoValue || ''}
                </a></Typography>
              </Box>
            </Box>
          </Box>
        </div>
        <div className={'filter-container'} style={{flex: "1 1 30%", width: '100%'}}>
          <form onSubmit={e => props.handleSubmitFilter(e)} noValidate autoComplete="off">
            <Box>
              <Typography variant={'h5'}>{FILTER_NAME_DATE.FILTER_TITLE}</Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'auto'}
              justifyContent={'center'}
              height={1}
              gridGap={8} marginTop={'8px'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gridGap={8}
              >
                <Box display={'flex'} gridGap={'4px'}>
                  <DatePicker
                    style={{flex: 'auto'}}
                    size='small'
                    color='secondary'
                    variant="inline"
                    orientation="portrait"
                    inputVariant="outlined"
                    label={FILTER_NAME_DATE.START_DATE_LABEL}
                    format="dd/MM/yyyy"
                    value={props.filterForm.startDate}
                    onChange={date => props.handleFilterFormChange(date, 'startDate')}
                  />
                  <DatePicker
                    style={{flex: 'auto'}}
                    size='small'
                    color='secondary'
                    variant="inline"
                    orientation="portrait"
                    inputVariant="outlined"
                    label={FILTER_NAME_DATE.END_DATE_LABEL}
                    format="dd/MM/yyyy"
                    value={props.filterForm.endDate}
                    onChange={date => props.handleFilterFormChange(date, 'endDate')}
                  />
                </Box>
                <Button
                  type='submit'
                  variant="contained"
                  color="secondary"
                  startIcon={<Icon>{FILTER_NAME_DATE.FILTER_ICON}</Icon>}
                >
                  {FILTER_NAME_DATE.FILTER_TEXT}
                </Button>
              </Box>
            </Box>
          </form>
        </div>
      </Box>
    </Box>
  );
};

FilterAssignment.propTypes = {
  name                  : PropTypes.string,
  handleFilterFormChange: PropTypes.func,
  handleSubmitFilter    : PropTypes.func,
  filterForm            : PropTypes.object,
  currentlyTutor        : PropTypes.object
};

export default FilterAssignment;
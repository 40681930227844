import React, {Fragment, useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./ClientsFormPage.scss";
import {useHistory, useParams} from "react-router";
import {getCiudades, getClient, saveClient, updateClient} from "../Core/TetRequests";
import {Box, CircularProgress, Icon, IconButton, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";

const ClientsFormPage = () => {
  const history = useHistory();
  const [user] = useState(JSON.parse(sessionStorage.getItem('userInfo')));
  const [idClient] = useState(useParams().id);
  const [options, setOptions] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [headerFormValues] = useState({
    idClient   : Number(idClient) || -1,
    usuario    : user.user,
    transaccion: idClient ? 'U' : 'I'
  });

  // region Get Domain Location - User
  useEffect(() => {
    const getClientDetail = (cities) => getClient(idClient).then(data => {
      if (data.length) {
        setFormValue(
          {
            nombreCompletoValue: data[0].nombreCompletoValue,
            correoValue        : data[0].correoValue,
            telefonoValue      : data[0].telefonoValue,
            idCiudadValue      : cities.find(city => city.id === data[0].idCiudadValue),
            universidadValue   : data[0].universidadValue
          })
        setIsButtonDisabled(false)
      }
    }).finally(() => {
      setLoading(false)
    })

    setLoading(true)
    getCiudades().then(data => {
      setOptions(data)
      return data
    }).then(cities => {
      if (idClient) {
        getClientDetail(cities)
      }
    }).finally(() => setLoading(false))
  }, [idClient]);
  // endregion

  // region Save/Update
  const handleButtonClick = () => {
    const client = {
      ...headerFormValues,
      ...formValue,
      idCiudadValue: formValue.idCiudadValue.id
    }

    setLoading(true);
    if (idClient) {
      updateClient(client).then(() => {
        setTimeout(() => {
          history.push("/clients");
        }, 500)
      }).finally(() => {
        setLoading(false);
      })
    } else {
      saveClient(client).then(() => {
        setTimeout(() => {
          history.push("/clients");
        }, 500)
      }).finally(() => setLoading(false))
    }
  };
  // endregion

  const [errorForm, setErrorForm] = useState({
    errorNombreCompleto: false,
    errorCorreo        : false,
    errorTelefono      : false,
    errorIdCiudad      : false,
    errorProfesion     : false,
  });
  const [formValue, setFormValue] = useState({
    nombreCompletoValue: '',
    correoValue        : '',
    telefonoValue      : '',
    idCiudadValue      : null,
    universidadValue   : '',
  });
  const handleInputChange = (event, nameInput = null, valueObject = null) => {
    let value = '';
    let name = '';
    if (!nameInput && !valueObject) {
      const target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    } else {
      value = valueObject;
      name = nameInput
    }
    const nameError = 'error' + name.slice(0, 1).toUpperCase() + name.slice(1, -5)

    setErrorForm({
      ...errorForm,
      [nameError]: !Boolean(value)
    })

    setFormValue({
      ...formValue,
      [name]: value,
    });
  }
  useEffect(() => {
    const allValuesInputs = Object.values(formValue)
    setIsButtonDisabled((allValuesInputs.some(value => value === null || value === '')))
  }, [formValue]);

  return (
    <Fragment>
      <Box className={'main-container'} boxShadow={2} position={'relative'}>
        <Box fontWeight={500} fontSize={'32px'}>
          <p>Información Cliente</p>
        </Box>
        <Box position={'absolute'} left={'10px'}>
          <Link to={`/clients`}>
            <IconButton>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Link>
        </Box>
      </Box>
      <Box display={'flex'} gridGap={'12px'} justifyContent={"space-between"} padding={2}>
        <Typography variant={"h4"}>{idClient ? `Edición Id: ${idClient}` : 'Creación'}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={{xs: "column", md: "row"}}>
        <Box flex={'1 1 50%'} display={'flex'}
             flexDirection={'column'}
             padding={'16px'}
             gridGap={'12px'}
        >
          <TextField
            error={errorForm.errorNombreCompleto}
            name={'nombreCompletoValue'}
            value={formValue.nombreCompletoValue}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            label="Nombre Completo"
            helperText="Ingrese nombre completo"
            variant="outlined"
          />

          <TextField
            error={errorForm.errorCorreo}
            name={'correoValue'}
            value={formValue.correoValue}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            label="Correo"
            helperText="Ingrese correo electrónico"
            variant="outlined"
            type='email'
            placeholder='example@example.com'
          />
          <Box display={'flex'} gridGap={'12px'} flexDirection={{xs: "column", sm: "row"}}>
            <Box flex={'1 1 40%'}>
              <TextField
                fullWidth
                error={errorForm.errorTelefono}
                name={'telefonoValue'}
                value={formValue.telefonoValue}
                onChange={e => handleInputChange(e)}
                color={'secondary'}
                label="Teléfono"
                helperText="Ingrese teléfono de contacto"
                variant="outlined"
                placeholder='3XX XXXXXXX'
                type='tel'
              />
            </Box>
            <Box flex={'1 1 60%'}>
              <Autocomplete
                options={options}
                name={'idCiudadValue'}
                getOptionLabel={(option) => option.description}
                value={formValue.idCiudadValue}
                onChange={(event, newValue) => {
                  handleInputChange(undefined, 'idCiudadValue', newValue)
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) =>
                  <TextField {...params}
                             error={errorForm.errorIdCiudad}
                             label="Ciudad"
                             variant="outlined"
                             color={'secondary'}
                             helperText="Ingrese ciudad"/>}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Divider orientation="vertical"/>
        </Box>
        <Box flex={'1 1 50%'} display={'flex'}
             flexDirection={'column'}
             padding={'16px'}
             gridGap={'12px'}
        >
          <TextField
            error={errorForm.errorUniversidad}
            name={'universidadValue'}
            value={formValue.universidadValue}
            onChange={e => handleInputChange(e)}
            color={'secondary'}
            label="Universidad"
            helperText="Ingrese Universidad"
            variant="outlined"
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <div className={'wrapper'}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={loading || isButtonDisabled}
            onClick={handleButtonClick}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </Button>
          {loading && <CircularProgress size={24} color={'secondary'} className={'buttonProgress'}/>}
        </div>
      </Box>
    </Fragment>
  );
};

export default ClientsFormPage;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const saveAsExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(
    data,
    fileName + EXCEL_EXTENSION
  );
}

export const exportAsExcelFile = (json, excelFileName, headers, widths) => {
  const worksheet = XLSX.utils.aoa_to_sheet(headers);

  XLSX.utils.sheet_add_json(worksheet, json, {skipHeader: true, origin: 'A' + (headers.length + 1)});

  worksheet["!cols"] = widths;

  const workbook = {
    Sheets    : {Reporte: worksheet},
    SheetNames: ['Reporte']
  };
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type    : 'array'
  });
  saveAsExcelFile(excelBuffer, excelFileName);
}


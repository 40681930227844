import "./FilterHeader.scss"

import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {Button, Icon, MenuItem, Typography} from "@material-ui/core";
import {FILTER_NAME_DATE} from "../../../../constants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const FilterYear = (props) => {
  return (
    <Box className={'main-container'} boxShadow={2}>
      <Box fontWeight={500} fontSize={'32px'}>
        <p>{props.name}</p>
      </Box>
      <div className={'filter-container'}>
        <form onSubmit={e => props.handleSubmitFilter(e)} noValidate autoComplete="off">
          <Box>
            <Typography variant={'h5'}>{FILTER_NAME_DATE.FILTER_TITLE}</Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={{xs: 'column', md: 'row'}}
            alignItems={{xs: 'auto', sm: 'flex-end'}}
            justifyContent={{xs: 'center', sm: 'flex-end'}}
            height={1}
            gridGap={8} marginTop={'8px'}>
            <Box
              display={'flex'}
              flexDirection={{xs: 'column', sm: 'row'}}
              gridGap={8}
            >
              <FormControl size='small' color='secondary' variant="outlined">
                <InputLabel>Año</InputLabel>
                <Select
                  value={props.value}
                  onChange={(e) => props.handlerOnChange(e)}
                  label="Year"
                  inputProps={{name: 'year'}}
                >
                  {props.years.map(option =>
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )}
                </Select>
              </FormControl>
              {/*<DatePicker
                size='small'
                color='secondary'
                variant="inline"
                orientation="portrait"
                inputVariant="outlined"
                label={FILTER_NAME_DATE.START_DATE_LABEL}
                format="dd/MM/yyyy"
                value={props.filterForm.startDate}
                onChange={date => props.handleFilterFormChange(date, 'startDate')}
              />*/}
              <Button
                type='submit'
                variant="contained"
                color="secondary"
                startIcon={<Icon>{FILTER_NAME_DATE.FILTER_ICON}</Icon>}
              >
                {FILTER_NAME_DATE.FILTER_TEXT}
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    </Box>
  );
};

FilterYear.propTypes = {
  name              : PropTypes.string,
  years             : PropTypes.array,
  handlerOnChange   : PropTypes.func,
  handleSubmitFilter: PropTypes.func,
  value             : PropTypes.string
};

export default FilterYear;
import React, {Fragment, useEffect, useState} from "react";
import {format, sub} from "date-fns";
import {getClient} from "../Core/TetRequests";
import FilterNameDates from "../shared/components/FilterHeader/FilterNameDates";
import BasicTableFilter from "../shared/components/BasicTableFilter/BasicTableFilter";
import {Icon, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";

const ClientsPage = () => {
  // region Filters
  const [filterForm, setFilterForm] = useState({
    startDate: sub(new Date(), {years: 1}),
    endDate  : new Date()
  });

  let handleFilterFormChange = (date, fieldName) => {
    setFilterForm({
        ...filterForm,
        [fieldName]: date
      }
    )
  }
  //endregion

  // region getDataTable
  const getData = () => {
    setShowLoading(true)
    getClient(undefined, format(filterForm.startDate, 'dd/MM/yyyy'), format(filterForm.endDate, 'dd/MM/yyyy')).then(data => {
      setDataTable(data)
    }).catch(() => {
      setErrorMessage('Ops.. Sucedió un error')
    }).finally(() => setShowLoading(false))
  }
  // endregion

  // region Component Mount
  useEffect(() => {
    getData()
  }, []);
  // endregion

  // region click on Filter Button
  let handleSubmitFilter = (event) => {
    event.preventDefault();
    getData()
  }
  // endregion

  // region ClientTable
  const [dataTable, setDataTable] = useState([]);
  const [dataHeader, setDataHeader] = useState([
    {widthReportColumn: 5, idColumn: 'idClient', label: 'ID', show: true},
    {widthReportColumn: 30, idColumn: 'nombreCompletoValue', label: 'Nombre', show: true},
    {
      widthReportColumn: 30, idColumn: 'correoValue', label: 'Correo', show: true,
      customRender     : (value, row) =>
        <a href={`mailto:${value || ''}`}>
          {value || ''}
        </a>
    },
    {
      widthReportColumn: 15, idColumn: 'telefonoValue', label: 'Teléfono', show: true,
      customRender     : (value, row) =>
        <a href={`tel:${value || ''}`}>
          {value || ''}
        </a>
    },
    {widthReportColumn: 10, idColumn: 'ciudadName', label: 'Ciudad', show: true},
    {widthReportColumn: 20, idColumn: 'universidadValue', label: 'Universidad', show: true},
    /*{widthReportColumn: 10, idColumn: 'cantidadTrabajos', label: 'Cant. Trabajos', show: true},
    {
      widthReportColumn: 10,
      idColumn         : 'valorTrabajos',
      label            : 'Valor',
      show             : true,
      customRender     : (value, row) => `$ ${value.toLocaleString('es-CO')}`
    },*/
    {widthReportColumn: 20, idColumn: 'fechaCreacion', label: 'Creación', show: false},
    {widthReportColumn: 15, idColumn: 'usuarioCreacion', label: 'Usr. Creación', show: false},
    {widthReportColumn: 20, idColumn: 'fechaModif', label: 'Modificación', show: false},
    {widthReportColumn: 15, idColumn: 'usuarioModif', label: 'Usr. Modificación', show: false},
    {
      idColumn: 'actions', label: 'Acciones', width: '100px', show: true, customRender: (value, row) => {
        return <Link to={`/clients/form/${row['idClient']}`}>
          <IconButton size={"small"}>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      }
    }
  ]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const handleEnableDisableColumn = (e, idColumn) => {
    const originalDataHeader = dataHeader.slice();
    originalDataHeader[originalDataHeader.findIndex(column => column.idColumn === idColumn)].show = e.target.checked
    setDataHeader(originalDataHeader)
  };
  // endregion

  return (<Fragment>
    <FilterNameDates name="Clientes"
                     handleFilterFormChange={handleFilterFormChange}
                     handleSubmitFilter={handleSubmitFilter}
                     filterForm={filterForm}/>
    <BasicTableFilter
      name='Clientes'
      columns={dataHeader}
      rows={dataTable}
      errorMessage={errorMessage}
      idColumnName='idClient'
      handleEnableDisableColumn={handleEnableDisableColumn}
      showLoading={showLoading}
      routeForm='clients/form'
    />

  </Fragment>);
};

export default ClientsPage;

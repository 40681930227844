import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import MainPage from "./pages/Main/MainPage";
import ClientPage from "./pages/Clients/ClientsPage";
import ClientsFormPage from "./pages/ClientsForm/ClientsFormPage";
import TutorePage from "./pages/Tutore/TutorePage";
import TutoreFormPage from "./pages/TutoreForm/TutoreFormPage";
import ReportPage from "./pages/Report/ReportPage"
import "./App.scss";
import Layout from "./pages/shared/components/Layout/Layout";
import HomePage from "./pages/Home/HomePage";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import environments from "./env/environments"
import AssignmentPage from "./pages/Assignments/AssignmentPage";
import AssignmentFormPage from "./pages/AssignmentForm/AssignmentFormPage";

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#ffc107",
      },
      secondary: {
        main: "#2196f3",
      },
    },
    typography: {
      fontSize: 12,
    },
  },
  esES
);

function App() {
  console.info(`${environments.VERSION}`)
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Fragment>
          <Router>
            <Switch>
              <Route exact path="/">
                <MainPage />
              </Route>
              <Layout>
                <Route exact path="/home">
                  <HomePage />
                </Route>
                <Route exact path="/clients">
                  <ClientPage />
                </Route>
                <Route exact path="/clients/form/:id?">
                  <ClientsFormPage />
                </Route>
                <Route exact path="/tutore">
                  <TutorePage />
                </Route>
                <Route exact path="/tutore/form/:id?">
                  <TutoreFormPage />
                </Route>
                <Route exact path="/assignments">
                  <AssignmentPage/>
                </Route>
                <Route exact path="/assignments/form/:id?">
                  <AssignmentFormPage />
                </Route>
                <Route exact path="/report">
                  < ReportPage/>
                </Route>
              </Layout>
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>
        </Fragment>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}


export default App;

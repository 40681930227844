export const menu = [
  {
    id: 1,
    name: 'Tutores',
    icon: 'School',
    role: ['A', 'P'],
    route: '/tutore'
  },
  {
    id: 2,
    name: 'Clientes',
    icon: 'AccountCircle',
    role: ['A', 'P'],
    route: '/clients'
  },
  {
    id: 3,
    name: 'Solicitudes',
    icon: 'Assignment',
    role: ['A', 'P', 'T'],
    route: '/assignments'
  },
  {
    id: 4,
    name: 'Reporte',
    icon: 'Widgets',
    role: ['P'],
    route: '/report'
  },
]
import React, {useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Box, ListItemIcon, useMediaQuery} from "@material-ui/core";
import logoImg from "../../../../assets/images/logo.png"
import {Link, NavLink} from "react-router-dom";
import AvatarUserButton from "./AvatarUserButton/AvatarUserButton";
import {menu} from "../../Constants/Constants";

import * as Icons from '@material-ui/icons/'
import "./Layout.scss"
import {useHistory} from "react-router";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  appBar       : {
    zIndex    : theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift  : {
    marginLeft: drawerWidth,
    width     : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen   : {
    width     : drawerWidth,
    transition: theme.transitions.create('width', {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerCloseMd: {
    transition: theme.transitions.create('width', {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX : 'hidden',
    width     : theme.spacing(7) + 1,

  },

  drawerCloseSm: {
    transition: theme.transitions.create('width', {
      easing  : theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX : 'hidden',
    width     : 0,
  },
  toolbar      : {
    display       : 'flex',
    alignItems    : 'center',
    justifyContent: 'space-between',
    padding       : theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

}));

let Layout = (props) => {
  const history = useHistory();
  const getOutHome = () => {
    sessionStorage.clear()
    history.push("/");
  }

  // region Permit access to page
  if (!sessionStorage.getItem('userInfo') || !sessionStorage.getItem('token')) {
    getOutHome()
  }
  // endregion
  const [user] = useState(
    JSON.parse(sessionStorage.getItem('userInfo')) ||
    {user: 'Invalid', type: 'Invalid'});
  const [allMenu] = useState(menu.filter(option => option.role.includes(user?.type || '')));
  const [open, setOpen] = useState(false);


  const isLowerThanSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMostThanMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const classes = useStyles();
  const theme = useTheme();


  const handleLogOut = () => {
    getOutHome()
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box width={1} height={1}>
      <CssBaseline/>
      {/*//region Header*/}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {[classes.appBarShift]: open})}
      >
        <Toolbar>
          <Box pl={0.5}
               pr={1}
               width={1}
               display="flex"
               flexDirection="row"
               justifyContent="space-between">
            <Box display='flex'
                 alignItems='center'
                 gridGap={8}>
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                className={open ? 'hide-item' : ''}>
                <MenuIcon/>
              </IconButton>
              <Link to={'/home'}>
                <img src={logoImg} alt="Logo" className={'img-logo ' + (open ? 'hide-item' : '')}/>
              </Link>
              <Typography variant="h6" noWrap>
                Tesos en Tesis
              </Typography>
            </Box>

            <AvatarUserButton
              userName={user.user}
              userRole={user.type}
              handleLogOut={handleLogOut}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {/*//endregion*/}
      {/*//region Main Content*/}
      <main className={'main-class'}>
        <article className={'component-container'}
                 style={{paddingLeft: isMostThanMd ? '65px' : '8px', top: isMostThanMd ? '65px' : '55px'}}>
          {props.children}
        </article>
      </main>
      {/*//endregion*/}
      <Drawer
        variant="permanent"
        className={clsx({
          [classes.drawerOpen]   : open,
          [classes.drawerCloseMd]: isMostThanMd && !open,
          [classes.drawerCloseSm]: isLowerThanSm && !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]   : open,
            [classes.drawerCloseMd]: isMostThanMd && !open,
            [classes.drawerCloseSm]: isLowerThanSm && !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Link to={'/home'}>
            <img src={logoImg} alt="Logo" className={'img-logo'}/>
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <List>
          {allMenu.map(item => (
            <NavLink activeClassName={'nav-item-active'} key={item.id} to={item.route}>
              <ListItem button>
                <ListItemIcon>
                  {React.createElement(Icons[item.icon])}
                </ListItemIcon>
                <ListItemText primary={item.name}/>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default Layout;
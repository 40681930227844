import React from "react";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {FormHelperText} from "@material-ui/core";
import Box from "@material-ui/core/Box";

import "./Login.scss";
import Typography from "@material-ui/core/Typography";

const Login = (props) => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={(e) => props.onSubmit(e)}>
      <Box
        marginTop="16px"
        display="flex"
        flexDirection="column"
        gridGap="16px"
      >
        <FormControl
          variant="outlined"
          color="secondary"
          fullWidth
          error={props.error}
        >
          <InputLabel htmlFor="input-user">Usuario</InputLabel>
          <OutlinedInput
            id="input-user"
            label={"Usuario"}
            name="user"
            value={props.formValues.user}
            placeholder={'nombre de usuario'}
            onChange={(e) => props.onChange(e)}
            endAdornment={
              <InputAdornment position="end">
                <AccountCircle/>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography component={'span'} variant={"body2"}>
              {props.error ? "Usuario Invalido." : "Ingrese su usuario."}
            </Typography>
          </FormHelperText>
        </FormControl>
        <FormControl
          variant="outlined"
          color="secondary"
          fullWidth
          error={props.error}
        >
          <InputLabel htmlFor="input-password">Contraseña</InputLabel>
          <OutlinedInput
            id="input-password"
            type={values.showPassword ? "text" : "password"}
            label="Contraseña"
            name="password"
            value={values.password}
            onChange={(e) => props.onChange(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography component={'span'} variant={"body2"}>
              {props.error ? "Contraseña Invalida." : "Ingrese su contraseña."}
            </Typography>
          </FormHelperText>
        </FormControl>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="secondary">
            Ingresar
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Login;

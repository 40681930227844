import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Login from "../Main/components/Login/Login";
import {MAIN_PAGE_CONSTANTS} from '../../constants';
import Fab from "@material-ui/core/Fab";
import "./MainPage.scss";
import imgMain from "../../assets/education.png";
import logoTet from "../../assets/logoTET.png";
import Snackbar from "@material-ui/core/Snackbar";
import Link from "@material-ui/core/Link";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useHistory} from "react-router";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import {Paper} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {doLogin} from "../Core/TetRequests";
import InstagramIcon from '@material-ui/icons/Instagram';

const MainPage = () => {
  const history = useHistory();
  const [values, setValues] = React.useState({
    error: false,
    open : false,
    form : {
      user    : "",
      password: "",
    },
  });
  const handleChange = (event) => {
    setValues({
      form: {
        ...values.form,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    doLogin(values.form.user, values.form.password).then(data => {
      setValues({...values, error: false});
      sessionStorage.setItem('userInfo', JSON.stringify({user: data.user, type: data.type, idUser: data.idUser}))
      sessionStorage.setItem('token', data.jwt)
      if (data.type.toLowerCase() !== 'x') history.push("/home");
    }).catch(() => {
      setValues({...values, error: true, open: true});
      sessionStorage.clear()
    })

  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setValues({
      ...values,
      open: false,
    });
  };

  return (


    <Box display="flex" flexDirection={{xs: "column", md: "row"}} height={1}>
      <Snackbar
        open={values.open}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          elevation={6}
          onClose={handleClose}
          variant="filled"
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {MAIN_PAGE_CONSTANTS.TXT_PW_ERROR}
        </Alert>
      </Snackbar>
      <Box flex='1 1 50%' padding={1} display="flex" flexDirection='column' justifyContent="center"
           alignItems={"center"}>
        <Paper>
          <Box padding={2} flexDirection={'column'} gridGap={1} maxWidth={'375px'}>
            <Typography variant="h5" gutterBottom className="txt-main" color={"secondary"}>
              <Box fontWeight={500}>{MAIN_PAGE_CONSTANTS.TXT_MAIN}</Box>
            </Typography>
            <Typography variant="subtitle2" style={{color: 'gray'}} gutterBottom>
              {MAIN_PAGE_CONSTANTS.TXT_CONTACT}
              <a href={MAIN_PAGE_CONSTANTS.URI_MAIL}>
                <strong>{MAIN_PAGE_CONSTANTS.TXT_MAIL}</strong>
              </a>
            </Typography>
            <Login
              onChange={handleChange}
              formValues={values.form}
              onSubmit={handleSubmit}
              error={values.error}
            />
            <Box marginTop={1}>
              <Divider/>
            </Box>
            <Typography variant="subtitle2" align={'center'} style={{color: 'gray'}}>
              {MAIN_PAGE_CONSTANTS.TXT_CONTACT_SECTION}
            </Typography>
            <Box marginTop={1} display='flex' flexDirection="row" justifyContent="center" gridGap="8px">
              <Link href={MAIN_PAGE_CONSTANTS.URI_FB_TET} target="_blank" style={{textDecoration: 'none'}}>
                <Fab className="icon-fb" variant="extended">
                  <FacebookIcon/>
                  <Box display={{xs: 'none', lg: 'block'}}>
                    {MAIN_PAGE_CONSTANTS.TXT_FB}
                  </Box>
                </Fab>
              </Link>
              <Link href={MAIN_PAGE_CONSTANTS.URI_WP_TET} target="_blank" style={{textDecoration: 'none'}}>
                <Fab className="icon-ws" variant="extended">
                  <WhatsAppIcon/>
                  <Box display={{xs: 'none', lg: 'block'}}>
                    {MAIN_PAGE_CONSTANTS.TXT_WA}
                  </Box>
                </Fab>
              </Link>
              <Link href={MAIN_PAGE_CONSTANTS.URI_INSTA_TET} target="_blank" style={{textDecoration: 'none'}}>
                <Fab variant="extended" className={'instagram'}>
                  <InstagramIcon/>
                  <Box display={{xs: 'none', lg: 'block'}}>
                    {MAIN_PAGE_CONSTANTS.TXT_INSTA}
                  </Box>
                </Fab>
              </Link>
              {/*<Link href={MAIN_PAGE_CONSTANTS.URI_MAIL} target="_blank" style={{textDecoration: 'none'}}>
                <Fab variant="extended" color={'secondary'}>
                  <EmailIcon/>
                  <Box display={{xs: 'none', lg: 'block'}}>
                    {MAIN_PAGE_CONSTANTS.TXT_EMAIL}
                  </Box>
                </Fab>
              </Link>*/}

            </Box>
          </Box>
        </Paper>
      </Box>
      <Box flex='1 1 50%' display={{xs: 'none', md: 'block'}} className={'pos-rel'}>
        <div className="main-img-logo">
          <img className="logo" src={logoTet} alt="education"/>
        </div>
        <div className="divImage">
          <img className="mainImage" src={imgMain} alt="education"/>
        </div>
      </Box>
    </Box>
  );
};

export default MainPage;

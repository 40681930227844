import axios from "axios";
import environment from "../../env/environments"


const TetRequest = {
  DOMAIN_URL    : 'general/locations',
  SECURITY_URL  : 'security/login',
  TUTOR_URL     : 'tutor',
  CLIENT_URL    : 'client',
  REPORT_URL    : 'report',
  ASSIGNMENT_URL: 'assignment',
  ASSIGNMENT_STATE_URL: 'assignment/status',
  ASSIGNMENT_STATES_DOMAIN_URL: 'general/states',
  ASSIGNMENT_CLIENTS_DOMAIN_URL: 'general/clients',
  ASSIGNMENT_TUTORS_DOMAIN_URL: 'general/tutors',
}

const getHeaders = () => {
  return {Authorization: sessionStorage.getItem('token')}
}

//region Security
export const doLogin = (user, password) => {
  return axios.post(
    `${environment.BASE_URL}${TetRequest.SECURITY_URL}`
    , {user: user, password: btoa(password)}
  ).then(response => response.data)
}
//endregion

//region Domain
export const getCiudades = () => {
  return axios.get(`${environment.BASE_URL}${TetRequest.DOMAIN_URL}`, {headers: getHeaders()})
    .catch(() => console.error('error'))
    .then(response => response.data)
}
//endregion

// region Tutor
export const saveTutor = (tutor) => {
  return axios.post(
    `${environment.BASE_URL}${TetRequest.TUTOR_URL}`
    , tutor,
    {headers: getHeaders()}
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
export const updateTutor = (tutor) => {
  return axios.put(
    `${environment.BASE_URL}${TetRequest.TUTOR_URL}`
    , tutor,
    {headers: getHeaders()}
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
export const getTutor = (idTutor = '', startDate = '', endDate = '') => {
  return axios.get(
    `${environment.BASE_URL}${TetRequest.TUTOR_URL}/${idTutor}`,
    {
      params : {
        startDate: startDate,
        endDate  : endDate,
        user     : JSON.parse(sessionStorage.getItem('userInfo'))['user']
      },
      headers: getHeaders()
    },
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
// endregion

// region Client
export const saveClient = (client) => {
  return axios.post(
    `${environment.BASE_URL}${TetRequest.CLIENT_URL}`
    , client,
    {headers: getHeaders()}
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
export const updateClient = (client) => {
  return axios.put(
    `${environment.BASE_URL}${TetRequest.CLIENT_URL}`
    , client,
    {headers: getHeaders()}
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
export const getClient = (idClient = '', startDate = '', endDate = '') => {
  return axios.get(
    `${environment.BASE_URL}${TetRequest.CLIENT_URL}/${idClient}`,
    {
      params : {
        startDate: startDate,
        endDate  : endDate,
        user     : JSON.parse(sessionStorage.getItem('userInfo'))['user']
      },
      headers: getHeaders()
    },
  )
    .catch(() => console.error('error'))
    .then(response => response.data)
}
// endregion


// region Report
export const getReportData = (year, idPaid) => {
  return axios.get(`${environment.BASE_URL}${TetRequest.REPORT_URL}/${year}/${idPaid}`, {headers: getHeaders()})
    .catch(() => console.error('error'))
    .then(response => response.data)
}
// endregion

// region Assignments
export const operateAssignments = (assignment) => {
  const url = `${environment.BASE_URL}${TetRequest.ASSIGNMENT_URL}`
  const headers = {headers: getHeaders()}

  return axios.post(url, assignment, headers)
    .catch(() => console.error('error'))
    .then(response => response.data)
}

export const setStateAssignments = (state) => {
  const url = `${environment.BASE_URL}${TetRequest.ASSIGNMENT_STATE_URL}`
  const headers = {headers: getHeaders()}

  return axios.post(url, state, headers)
    .catch(() => console.error('error'))
    .then(response => response.data)
}

export const getStatesAssignments = () => {
  const url = `${environment.BASE_URL}${TetRequest.ASSIGNMENT_STATES_DOMAIN_URL}`
  const headers = {headers: getHeaders()}

  return axios.get(url, headers)
    .catch(() => console.error('error'))
    .then(response => response.data)
}

export const getClientsAssignments = () => {
  const url = `${environment.BASE_URL}${TetRequest.ASSIGNMENT_CLIENTS_DOMAIN_URL}`
  const headers = {headers: getHeaders()}

  return axios.get(url, headers)
    .catch(() => console.error('error'))
    .then(response => response.data)
}

export const getTutorsAssignments = () => {
  const url = `${environment.BASE_URL}${TetRequest.ASSIGNMENT_TUTORS_DOMAIN_URL}`
  const headers = {headers: getHeaders()}

  return axios.get(url, headers)
    .catch(() => console.error('error'))
    .then(response => response.data)
}

// endregion